<template>
  <div>
    <counter-card v-if="showCounter" :avatarImgUrl="avatarImgUrl" :avatarName="avatarName" :initCounter="initCounter"/>
    <div id="meet"/>
  </div>
</template>

<script>

import { VideoCallStatus } from './enums/VideoCallStatus'
import { Firestore } from '../firebase'
import { loadScript } from 'vue-plugin-load-script'
import CounterCard from '../components/cards/CounterCard.vue'

export default {
  props: {
    id: String,
    callId: String,
    avatarImgUrl: String,
    avatarName: String
  },
  data () {
    return {
      showCounter: true,
      initCounter: 10
    }
  },
  components: {
    CounterCard
  },
  mounted () {
    this.AcceptCall(this.id)
    loadScript('https://meet.jit.si/external_api.js')
      .then(() => {
        console.log('script fetched successfully')
        setTimeout(() => {
          const jitsi = this.JoinRoom(this.callId)
          this.CheckCallStatus(this.id, jitsi)
          this.showCounter = false
        }, this.initCounter * 1000)
      })
      .catch(() => {
        console.log('Failed to fetch script')
      })
  },
  methods: {
    AcceptCall (videoCallDocId) {
      Firestore
        .collection('videocalls')
        .doc(videoCallDocId)
        .update({
          status: VideoCallStatus.Accepted
        })
    },
    JoinRoom (videoCallId) {
      const domain = 'meet.jit.si'
      const options = {
        roomName: videoCallId,
        width: window.innerWidth,
        height: window.innerHeight,
        parentNode: document.querySelector('#meet'),
        userInfo: {
          displayName: 'Tablet'
        },
        configOverwrite: {
          prejoinPageEnabled: false,
          disableDeepLinking: true,
          toolbarButtons: [
          ]
        }
      }
      // eslint-disable-next-line no-undef
      const jitsi = new JitsiMeetExternalAPI(domain, options)
      return jitsi
    },
    CheckCallStatus (docId, jitsi) {
      console.log('CheckCallStatus called for docId: ' + docId)
      Firestore
        .collection('videocalls')
        .doc(docId)
        .onSnapshot((doc) => {
          if (doc.data().status === VideoCallStatus.Hungup) {
            doc.ref.update({ status: VideoCallStatus.Ended })
            jitsi.dispose()
            // go to home view
            this.$router.push('/')
          }
        })
    }
  }
}
</script>
