<template>
    <v-layout  align-center class="justify-center">
      <div class="counterCard">
        <v-row>
          <v-col cols="3" >
            <v-avatar size="160" class="mt-3 overlay backdrop-contrast-50">
              <div class="avatar-box pt-5 h-20 w-20 text-2xl z-10 absolute">
                {{ counter }}
              </div>
              <img
                :alt="'user'"
                :src="avatarImgUrl"
                class="z-0"
              >
            </v-avatar>
            <p class="text-2xl pl-10 pt-2">
              {{ avatarName }}
            </p>
          </v-col>
        </v-row>
      </div>
    </v-layout>
</template>

<script>

export default {
  name: 'CounterCard',
  props: {
    avatarImgUrl: String,
    avatarName: String,
    initCounter: Number
  },
  data () {
    return {
      counter: this.initCounter
    }
  },
  created () {
    setInterval(() => {
      if (this.counter > 0) {
        this.counter -= 1
      }
    }, 1000)
  }
}
</script>
<style lang="scss">
@import '../../assets/styles/_variables.scss';

.v-application ul, .v-application ol {
  padding-left: 0px !important;
}
.counterCard {
  height: 600px;
  width: 1000px;
  padding-top: 150px;
  padding-left: 420px;
  color: white;
  background-color: $card-color;
}
.avatar-box{
  background-color: rgba(#000, .6);
}
</style>
